import React from 'react'

import Layout from '../../../components/layout'
import SEO from '../../../components/seo'
import SectionHeader from '../../../components/SectionHeader/SectionHeader'
import Intro from '../../../components/Intro/Intro'
import Comparison from '../../../components/comparison/Comparison';
import HubSpotForm from '../../../components/HubSpotForm/HubSpotForm'
import Outcomes from '../../../components/Outcomes/Outcomes'

const EmersoftVsIndiecommerce = () => {

    const comparisonDataGeneral = [
        {
          heading: "Emersoft Ingram Shopify",
          items: [
            {
              heading: "Monthly Platform Fee",
              description: "Starting at $29 (Shopify Basic Plan)"
            },
            {
              heading: "Setup Fee",
              description: "$1,500 (one-time, with annual billing)"
            },
            {
              heading: "Monthly App Fee",
              description: "$250 (with annual billing)"
            },
            {
              heading: "Transaction Fees",
              description: "2.9% + 30¢ per transaction (Shopify Payments)"
            },
            {
                heading: "Customization & Branding Freedom",
                description: "Unlimited customization – Full control over design, layout, fonts, and colors to match your brand vision."
            },
            {
                heading: "Rebrand-Friendly",
                description: "Yes – Adapts to your evolving brand and scales with your business."
            },
            {
                heading: "Automated Order Fulfillment",
                description: "Fully automated with Ingram CDF Lite integration – retains the backend automation IndieCommerce users like."
            },
            {
                heading: "Reporting & Accounting Integration",
                description: "Extensive reporting features with integration to major accounting software (e.g., QuickBooks, Xero)"
            },
            {
                heading: "SEO and Marketing",
                description: "Built-in SEO and marketing tools to drive traffic"
            },
          ]
        },
        {
          heading: "IndieCommerce",
          items: [
            {
                heading: "Monthly Platform Fee",
                description: "$175 (IndieCommerce)"
            },
            {
                heading: "Setup Fee",
                description: "Not transparent – unclear pricing makes it difficult to budget."
            },
            {
                heading: "Monthly App Fee",
                description: "Not transparent – hidden costs possible."
            },
            {
                heading: "Transaction Fees",
                description: "1% of sales (capped at $500/month) – scales up with sales, costing more as you grow."
            },
            {
                heading: "Customization & Branding Freedom",
                description: "Severely limited customization – Must use IndieCommerce-approved templates with little creative flexibility."
            },
            {
                heading: "Rebrand-Friendly",
                description: "No – Forces a generic look that doesn’t reflect your unique bookstore personality."
            },
            {
                heading: "Automated Order Fulfillment",
                description: "Basic automation – Works but is paired with an outdated frontend experience."
            },
            {
                heading: "Reporting & Accounting Integration",
                description: "Limited – Standard reporting with fewer integrations."
            },
            {
                heading: "SEO and Marketing",
                description: "Basic SEO only – No extra marketing tools to drive traffic."
            },
          ]
        }
      ];

      const comparisonDataDetailed = [
        {
          heading: "Emersoft Ingram Shopify",
          items: [
            {
              heading: "Target Audience",
              description: "Ideal for any bookstore owner looking to maximize online sales—with or without an existing physical store—by leveraging a flexible, robust, and scalable platform."
            },
            {
              heading: "Setup & Ease of Use",
              description: "Extremely user-friendly with an intuitive interface; quick and hassle-free setup that requires no additional hires, saving time and money."
            },
            {
              heading: "Customization & Branding",
              description: "Provides extensive theme customization and full branding control, allowing you to create a distinctive online store that truly reflects your identity without technical headaches."
            },
            {
              heading: "Product Management",
              description: "Delivers direct access to Ingram’s vast inventory via the Emersoft App, giving you full control over which titles to feature, how to price them, and how to manage promotions."
            },
            {
                heading: "Costs & ROI",
                description: "Transparent pricing: a one-time setup fee (as low as $1,500 with annual billing) plus a clear monthly fee and an affordable Shopify subscription (starting at $29/month); high ROI potential through automated efficiency and scalability."
            },
            {
                heading: "Automation & Fulfillment",
                description: "Seamless, fully automated order processing with Emersoft’s integration—orders are automatically fulfilled through Ingram CDF Lite, saving you time and reducing errors."
            },
            {
                heading: "Reporting & Integrations",
                description: "Offers extensive reporting features and integrates with all major accounting software (like QuickBooks and Xero), ensuring you get detailed financial insights and streamlined bookkeeping."
            },
            {
                heading: "Scalability & Support",
                description: "Backed by Shopify’s robust support network and the dedicated Emersoft team; the solution scales effortlessly as your business expands, with no need for extra technical hires."
            },
            {
                heading: "Overall Value Proposition",
                description: "A superior, all-in-one solution that enhances profitability by combining ease of use, complete control, advanced reporting, and powerful automation—ensuring you make more money with less hassle."
            },
          ]
        },
        {
          heading: "IndieCommerce",
          items: [
            {
            heading: "Target Audience",
            description: "Exclusively for ABA member brick-and-mortar bookstores; limits your options if you want a truly modern online presence."
            },
            {
            heading: "Setup & Ease of Use",
            description: "Can require technical setup and may involve additional costs for customization; not ideal for non-technical users."
            },
            {
            heading: "Customization & Branding",
            description: "Offers limited design flexibility with preset templates that may restrict your ability to fully represent your unique brand."
            },
            {
            heading: "Product Management",
            description: "Grants access to a 14+ million book catalog but offers less control over product selection and pricing; you’re largely confined to predefined parameters."
            },
            {
                heading: "Costs & ROI",
                description: "Fixed monthly fees combined with a 1% sales commission (capped at $500/month) can add up; ROI and overall costs are less transparent."
            },
            {
                heading: "Automation & Fulfillment",
                description: "Basic automation capabilities that may still require manual intervention for order processing and fulfillment."
            },
            {
                heading: "Reporting & Integrations",
                description: "Provides standard reporting tools with limited integration options; may not fully satisfy accounting and financial tracking needs."
            },
            {
                heading: "Scalability & Support",
                description: "May need additional technical support as your business grows, potentially incurring extra costs."
            },
            {
                heading: "Overall Value Proposition",
                description: "Viable for existing ABA members but comes with inherent limitations that can restrict growth and profitability."
            },
          ]
        }
      ];

      const pricingMonthly = [
        {
          id: 111,
          title: '$3000 one-time',
          subtitle: 'Shopify Ingram App Setup Fee',
        },
        {
          id: 222,
          title: '$300 / month',
          subtitle: 'Data and App Access',
        },
      ]
    
      const pricingYearly = [
        {
          id: 111,
          title: '$1500 one-time',
          subtitle: 'Shopify Ingram App Setup Fee (50% off)',
        },
        {
          id: 222,
          title: '$250 / month',
          subtitle: 'Data and App Access ($3000 billed yearly)',
        },
      ]
    return (
        <Layout>
            <SEO
            title="Shopify + Emersoft Ingram App vs. IndieCommerce: The Ultimate Bookstore E-commerce Comparison. The best IndieCommerce alternative."
            keywords={[
            `Emersoft`,
            `application`,
            `react`,
            `ai`,
            `gpt`,
            `openai`,
            `Emersoft Ingram Shopify Integration`,
            `IndieCommerce comparison`,
            `IndieCommerce alternative`,
            `Shopify bookstore solutions`,
            `Independent bookstore e-commerce`,
            `Best online bookstore platform`
            ]}
            description="Discover why integrating Shopify with Emersoft's Ingram App offers superior customization, automation, and ROI for booksellers compared to IndieCommerce. Explore our detailed analysis to make an informed decision for your bookstore's online success"
        />
        <SectionHeader>
            Why Choose Emersoft’s Ingram Shopify Integration Over IndieCommerce?
        </SectionHeader>
        <Intro
            paragraph1="Shopify + Emersoft vs. IndieCommerce"
        />
        <Comparison data={comparisonDataGeneral} />
        <Intro
            paragraph1="Why Shopify + Emersoft Outperforms IndieCommerce"
        />
        <Comparison data={comparisonDataDetailed} />
        <Intro paragraph1="Pricing" />
        <Outcomes heading="Monthly" outcomes={pricingMonthly} noMarginBottom={true} />
        <Outcomes heading="Annually" outcomes={pricingYearly} />
        <HubSpotForm 
            formId="93f2ee63-651c-439f-bdff-9c02aafe3e74" 
            portalId="46844019" 
            heading="Start Automating Today"
            paragraph="Get started with Ingram's Shopify integration today and see the difference it makes for your business."
        />
        </Layout>
    )
}

export default EmersoftVsIndiecommerce;